import { Flex, Title, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import { i18n } from "../i18n/langType";
import { useIntl } from "react-intl";

export function SessionError(props: { chargePointId: string | undefined }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useMantineTheme();
  return (
    <Flex align="center" justify="center" pl="md" pb="md" bg={theme.white}>
      <IconArrowLeft size="24" color={theme.colors.gray[6]} />
      <Title
        onClick={() => {
          navigate(`/${props.chargePointId}`);
        }}
        style={{ cursor: "pointer", color: "gray.6", textAlign: "center" }}
        size="h5"
        mr="sm"
      >
        {intl.formatMessage({ id: i18n.tryAgain })}
      </Title>
    </Flex>
  );
}
