import {
  ActionIcon,
  Button,
  Center,
  CloseButton,
  Container,
  Drawer,
  Flex,
  Input,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
// import { posthog } from "posthog-js";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";
import { useAtom } from "jotai";
import { currentUserSession } from "../atoms/jotaiAtoms";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { UserInfo } from "../types/types";
import { updateBillingInfo } from "../api/userSessions";

export default function BillingInfo(props: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  country: string | undefined;
}) {
  const intl = useIntl();
  const theme = useMantineTheme();
  // const paymentStore = useStore(paymentFlowStoreMap);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [userSession] = useAtom(currentUserSession);
  const [requiredErrorState, setRequiredErrorState] = useState(false);

  return (
    <Drawer
      transitionProps={{
        transition: "slide-up",
        duration: 500,
        timingFunction: "ease",
      }}
      position="bottom"
      opened={props.opened}
      onClose={() => {
        props.setOpened(false);
      }}
      padding="sm"
      size="xl"
      withCloseButton={false}
      pos="relative"
    >
      <Container bg={theme.white} mih="80vh" style={{ paddingBottom: "7rem" }}>
        <CloseButton onClick={() => props.setOpened(false)} style={{ position: "absolute", top: 10, right: 10 }} />
        <Flex gap="md" direction="column">
          <div>
            <Text size="xl" fw={700} p={"1rem"}>
              {intl.formatMessage({ id: i18n.billingInfoTitle })}
            </Text>
            <TextInput
              value={userInfo?.emailAddress}
              onChange={(event) => setUserInfo({ ...userInfo, emailAddress: event.target.value })}
              size="lg"
              type="email"
              name="email"
              autoComplete="on"
              placeholder={`${intl.formatMessage({ id: i18n.emailAddress })} (${intl.formatMessage({
                id: i18n.required,
              })})`}
              error={requiredErrorState ? intl.formatMessage({ id: i18n.required }) : false}
            />
          </div>
          <div>
            <TextInput
              value={userInfo?.firstName}
              onChange={(event) => setUserInfo({ ...userInfo!, firstName: event.target.value })}
              size="lg"
              type="text"
              name="firstName"
              id="firstName"
              autoComplete="given-name"
              placeholder={intl.formatMessage({ id: i18n.firstName })}
            />
          </div>
          <div>
            <TextInput
              value={userInfo?.lastName}
              onChange={(event) => setUserInfo({ ...userInfo!, lastName: event.target.value })}
              size="lg"
              type="text"
              name="lastName"
              id="lastName"
              autoComplete="family-name"
              placeholder={intl.formatMessage({ id: i18n.lastName })}
            />
          </div>
          <div>
            <TextInput
              value={userInfo?.address}
              onChange={(event) => setUserInfo({ ...userInfo!, address: event.target.value })}
              size="lg"
              type="text"
              name="street-address"
              id="address"
              autoComplete="street-address"
              placeholder={intl.formatMessage({ id: i18n.address })}
            />
          </div>
          <div>
            <TextInput
              value={userInfo?.postalCode}
              onChange={(event) => setUserInfo({ ...userInfo!, postalCode: event.target.value })}
              size="lg"
              type="text"
              name="postalCode"
              id="postalCode"
              autoComplete="postal-code"
              placeholder={intl.formatMessage({ id: i18n.postalCode })}
            />
          </div>
          <div>
            <TextInput
              value={userInfo?.city}
              onChange={(event) => setUserInfo({ ...userInfo!, city: event.target.value })}
              size="lg"
              type="text"
              name="city"
              id="city"
              autoComplete="address-level2 city"
              placeholder={intl.formatMessage({ id: i18n.city })}
            />
          </div>
        </Flex>
        <Center ml="sm" mr="sm" h="10rem">
          <Button
            onClick={async () => {
              // posthog.capture('billingDetailsAdded');
              if (userInfo?.emailAddress === undefined) {
                setRequiredErrorState(true);
                return;
              }
              if (userSession?.user_session_id === undefined || props.country === undefined) {
                //show error for missing user session id or missing country, Doubt this is common, as we will load it immediately upon entering the parent page
                return;
              }
              try {
                const billingDetailsFetch = await updateBillingInfo(
                  userInfo,
                  userSession?.user_session_id,
                  props.country
                );

                if (billingDetailsFetch.status === 200) {
                  //todo: display some sort of success for user?
                  props.setOpened(false);
                } else if (billingDetailsFetch.status > 400) {
                  console.error("Error in setting billing details");
                  //todo: display some sort of try again error?
                }
              } catch (error) {
                console.error("Error updating billing info");
              }
            }}
            fullWidth
            rightSection={<IconArrowRight />}
            color="green"
            radius={"lg"}
            size="md"
            tt="uppercase"
            mt="md"
          >
            {intl.formatMessage({ id: i18n.submitBillingInfo })}
          </Button>
        </Center>
      </Container>
    </Drawer>
  );
}
