import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./pages/errorPage";
import "./App.css";
import TranslationWrapper from "./components/translationWrapper";
import { SplashPage } from "./pages/splashPage";
import NotFound from "./pages/notFound";
import ChargerIdValidator from "./chargerIdValidator";
import HealthPage from "./pages/healthPage";

//routing is in this function
function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <TranslationWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/health" element={<HealthPage />} />
            <Route path=":chargerId/*" element={<ChargerIdValidator />} />
            <Route path="" element={<SplashPage />} />{" "}
            {/*TODO:: Add error route for no charger, maybe in chargerPage?*/}
            <Route path="*" element={<NotFound />}> </Route>
          </Routes>
        </BrowserRouter>
      </TranslationWrapper>
    </div>
  );
}

export default App;
