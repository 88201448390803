import React from "react";
import { Box, Container, Stack, useMantineTheme, Text, Button } from "@mantine/core";
import HeaderLayout from "../components/layout/Header";
import { useAtom } from "jotai";
import { currentChargePoint } from "../atoms/jotaiAtoms";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useMantineTheme();
  const [chargePoint] = useAtom(currentChargePoint);

  return (
    <Container bg="#fafafa" px="0" mih="100vh">
      <Box pos="relative">
        <HeaderLayout
          country={chargePoint?.country}
          title={
            chargePoint?.isAvailable
              ? intl.formatMessage({ id: i18n.title })
              : intl.formatMessage({ id: i18n.titleFailure })
          }
        />
        <Container fluid bg={theme.white} pb="15px">
          <Stack>
            <Text>{intl.formatMessage({ id: i18n.errorOccured })}</Text>
            <Text style={{ textAlign: "justify" }}>{intl.formatMessage({ id: i18n.genericErrorMessage })}</Text>
            <Button
              onClick={() => {
                navigate("/");
              }}
              color="green"
              style={{ fontWeight: 500 }}
              radius={theme.radius.xl}
              size="lg"
              mb="sm"
            >
              {intl.formatMessage({ id: i18n.backToHome })}
            </Button>
          </Stack>
        </Container>
      </Box>
    </Container>
  );
}
