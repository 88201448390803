import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";
import PaymentPage from "./pages/pay";
import ChargePointLandingLoader from "./pages/chargePointLandingLoader";
import ErrorPage from "./pages/errorPage";
import "./App.css";
import { Suspense } from "react";
import PaymentCompletePage from "./pages/paymentComplete";
import { IntlProvider, useIntl } from "react-intl";
import TranslationWrapper from "./components/translationWrapper";
import { i18n } from "./i18n/langType";
import { SplashPage } from "./pages/splashPage";
import NotFound from "./pages/notFound";


type ChargerIdParams = {
  chargerId: string;
};

export default function ChargerIdValidator() {
  const intl = useIntl();
  const { chargerId } = useParams<keyof ChargerIdParams>() as ChargerIdParams;
  const isValidChargerId = /^[a-zA-Z0-9_-]{1,36}$/.test(chargerId);

  if (!isValidChargerId) {
    return <Navigate to="/error" />;
  }

  return (
    <Routes>
      <Route path="pay" element={<PaymentPage />} />
      <Route path="complete/:userSessionId" element={<PaymentCompletePage />} />
      <Route path="complete/:userSession" element={<PaymentCompletePage />} />
      <Route path="*" element={<NotFound />}> </Route>
      <Route
              path=""
              element={
                <Suspense fallback={<div>{intl.formatMessage({ id: i18n.loadingChargePoint })}</div>}>
                  <ChargePointLandingLoader />
                </Suspense>
              }
            />
    </Routes>
  );
}