import { Button, Container, Drawer, Flex, useMantineTheme, Text, ThemeIcon } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { ReactNode, useState } from "react";
import { i18n } from "../i18n/langType";
import { FormattedMessage } from "react-intl";

export const Feature = ({
  icon,
  hasDrawer,
  children,
  noBorder,
  hasArrow,
  nameI18n,
  descriptionI18n,
  valueI18n,
}: {
  hasArrow?: boolean;
  noBorder?: boolean;
  icon: JSX.Element;
  hasDrawer?: boolean;
  children?: ReactNode;
  nameI18n: string;
  descriptionI18n: string;
  valueI18n: string;
}) => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  return (
    <Flex
      style={
        hasDrawer
          ? {
              textWrap: "nowrap",
              cursor: "pointer",
            }
          : {
              textWrap: "nowrap",
            }
      }
      gap="none"
      direction="column"
      pr="sm"
      align={"center"}
    >
      <ThemeIcon onClick={() => hasDrawer && setOpened(true)} size="lg" color={"none"}>
        {icon}
      </ThemeIcon>
      <Text onClick={() => hasDrawer && setOpened(true)} color={theme.colors.gray[4]} size="sm" fw="light">
        {nameI18n}
      </Text>
      <Text
        onClick={() => hasDrawer && setOpened(true)}
        // sx={{ fontFamily: 'Suisse Intl, sans-serif' }}
        size="xl"
        fw="600"
      >
        {valueI18n}
      </Text>
      <Flex justify="center">
        <Text
          style={hasArrow ? { marginRight: "2px" } : {}}
          onClick={() => hasDrawer && setOpened(true)}
          color={theme.colors.gray[4]}
          size="sm"
          fw="light"
        >
          {descriptionI18n}
        </Text>{" "}
        {hasArrow ? <IconArrowRight color={theme.colors.gray[4]} size="14" /> : null}
      </Flex>
      {hasDrawer && (
        <Drawer
          transitionProps={{
            transition: "slide-up",
            duration: 500,
            timingFunction: "ease",
          }}
          position="bottom"
          opened={opened}
          onClose={() => setOpened(false)}
          padding="sm"
          size="xl"
          withCloseButton={false}
          h="100%"
        >
          <Container size="lg" pos="relative" h="100%" display="flex" style={{ flexDirection: "column" }}>
            <Container
              bg={theme.colors.gray[0]}
              h="3px"
              w="xl"
              pos="absolute"
              mx="auto"
              top="5px"
              left="50%"
              style={{ transform: "translate(-50%, -50%)" }}
            />
            {children}
            <Flex pt="md" mt="auto" direction="column" align="flex-end" justify="flex-end">
              <Button
                onClick={() => setOpened(false)}
                color="dark"
                style={{ fontWeight: 500 }}
                radius="xl"
                size="md"
                tt="uppercase"
                mb="sm"
              >
                <FormattedMessage id={i18n.close} />
              </Button>
            </Flex>
          </Container>
        </Drawer>
      )}
    </Flex>
  );
};
