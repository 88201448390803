import { ActionIcon, Button, Container, Drawer, Flex, Group, Image, Text, Title, useMantineTheme } from "@mantine/core";
import { IconArrowLeft, IconArrowRight, IconHelp } from "@tabler/icons-react";
import { useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { COUNTRY_AVAILABLE } from "../../types/types";
import LocaleSelector from "../localeSelector";
import { i18n } from "../../i18n/langType";
import { countryConfig } from "../../utils/countryConfig";

type Enumerate<N extends number, Acc extends number[] = []> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export default function HeaderLayout({
  backTo,
  title,
  description,
  country,
}: {
  title?: string;
  backTo?: "choose" | "charging" | "pay" | "invoice" | "paymentAuthorized";
  description?: string;
  country?: string;
}) {
  const theme = useMantineTheme();
  const intl = useIntl();
  const [opened, setOpened] = useState(false);
  return (
    <Container px="md" pt="sm" pb="lg" bg={theme.white} w="100%">
      <Flex justify={backTo ? "space-between" : "flex-end"} align="center" pb="sm">
        {backTo && (
          <ActionIcon
            onClick={() => {
              // history.back();
            }}
            size="xl"
            component="a"
          >
            <IconArrowLeft />
          </ActionIcon>
        )}
        <Group justify="center">
          <Drawer
            transitionProps={{
              transition: "slide-up",
              duration: 500,
              timingFunction: "ease",
            }}
            position="bottom"
            opened={opened}
            onClose={() => setOpened(false)}
            padding="sm"
            size="xl"
            withCloseButton={false}
          >
            <Container size="lg" pos="relative">
              <Container
                bg={theme.colors.gray[0]}
                h="3px"
                w="xl"
                pos="absolute"
                mx="auto"
                top="-16px"
                left="50%"
                style={{ transform: "translate(-50%, -50%)" }}
              />
              <Image src="/help.png" width="100%" style={{ maxWidth: "500px" }} mt="md" />
              <Title size="md" mt="lg" mb="sm">
                <FormattedMessage id={i18n.helpAndSupport} />
              </Title>
              <Flex align="center" mb="md">
                <a
                  href={countryConfig[(country as COUNTRY_AVAILABLE) || "DEU"]?.impressum}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Title size="h5" mr="sm" style={{ color: "gray.6" }}>
                    {intl.formatMessage({ id: i18n.impressum })}
                  </Title>
                </a>
                <IconArrowRight size="24" color={theme.colors.gray[6]} />
              </Flex>
              <Text color="gray.4">{intl.formatMessage({ id: i18n.emailForSupport })}</Text>
              <Flex mt="md" direction="column" align="flex-end" justify="flex-end">
                <a href={countryConfig[(country as COUNTRY_AVAILABLE) ?? "DEU"]?.supportLink}>
                  <Button
                    data-attr="posthog-feedback-button"
                    rightSection={<IconArrowRight size={14} />}
                    color="dark"
                    radius="xl"
                    size="lg"
                    mb="sm"
                    style={{ fontWeight: 500 }}
                  >
                    {intl.formatMessage({ id: i18n.help }).toUpperCase()}
                  </Button>
                </a>
                <Button
                  onClick={() => setOpened(false)}
                  color="gray.2"
                  style={{ color: theme.black, fontWeight: 500 }}
                  radius="xl"
                  size="lg"
                  mb="sm"
                  px="40px"
                >
                  {intl.formatMessage({ id: i18n.close }).toUpperCase()}
                </Button>
              </Flex>
            </Container>
          </Drawer>
          <ActionIcon
            color="none"
            style={{ color: "grey" /*Color in mantine refers to background color for this component*/ }}
            size="md"
            onClick={() => setOpened(true)}
          >
            <IconHelp />
          </ActionIcon>
          <LocaleSelector />
        </Group>
      </Flex>
      {title && (
        <Title pt="lg" pb="20px">
          {title}
        </Title>
      )}
      {description && (
        <Text size="md" color="gray.6" mt="md">
          {description}
        </Text>
      )}
    </Container>
  );
}
