export const countryConfig: {
  [key: string]: {
    terms: string;
    privacy: string;
    impressum: string;
    supportLink: string;
    productPage: string;
    region: string;
  };
} = {
  DEU: { //links can be gotten from Tobias Beau, Renee if dead links and cant find them manually by going through apcoa page per country
    terms: "https://www.apcoa.de/fl-bedingungen/",
    privacy: "https://www.apcoa.de/datenschutzerklaerung/",
    impressum: "https://www.apcoa.de/ueber-apcoa/unternehmen/impressum/",
    supportLink: "mailto:customerservice@apcoa.de",
    productPage: "https://www.apcoa.de/parkprodukte/e-fahrzeuge-laden/",
    region: "Deutschland",
  },
  AUT: {
    terms: "https://www.apcoa.at/nutzungsbestimmungen-e-laden",
    privacy: "https://www.apcoa.at/datenschutz-web-services",
    impressum: "https://www.apcoa.at/impressum/",
    supportLink: "mailto:office@apcoa.at",
    productPage: "https://www.apcoa.at/parkprodukte/park-charge/",
    region: "Österreich",
  },
// https://www.apcoa.se/integritetspolicy/
  SWE: {//daniel jorennsson
    terms: "todo", //TODO: Get these links from sweden people
    privacy: "todo",
    impressum: "todo",
    supportLink: "todo",
    productPage: "todo",
    region: "todo",
  },
}; //TODO: make a default terms and agreements? Does that even work? I imagine not legally, but we default to DE?
