import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../i18n/en.json";
import German from "../i18n/de.json";
import Swedish from "../i18n/sv.json";
import { Translations } from "../i18n/langType";
import { useAtom } from "jotai";
import { locale } from "../atoms/jotaiAtoms";
import { StripeElementLocale } from "@stripe/stripe-js";

const defaultValue: any = "en";
export const Context = React.createContext(defaultValue);
const defaultLocale = navigator.language || window.navigator.language;
let lang: Translations = getMessagesFromLocale(defaultLocale);

function getMessagesFromLocale(locale: string) {
  switch (locale.toLowerCase()) {
    case "en":
    case "en-us":
    case "en-au":
    case "en-ca":
    case "en-nz":
    case "en-gb":
      return English;
      break;
    case "de":
      return German;
      break;
    case "sv":
      return Swedish;
      break;
    default:
      return English;
  }
}

export function getLanguageNameFromLocale(locale: string) {
  switch (locale.toLowerCase()) {
    case "en":
    case "en-us":
    case "en-au":
    case "en-ca":
    case "en-nz":
    case "en-gb":
      return "English";
      break;
    case "de":
      return "Deutsch";
      break;
    case "sv":
    case "se":
      return "Svenska";
      break;
    default:
      return "English";
  }
}

const TranslationWrapper = (props: any) => {
  const [curLocale, setLocale] = useAtom(locale);
  const [messages, setMessages] = useState(lang);
  function selectLocale(locale: string) {
    const newLocale: string = locale;
    setLocale(newLocale as StripeElementLocale);
    setMessages(getMessagesFromLocale(newLocale));
  }
  return (
    <Context.Provider value={{ curLocale, selectLanguage: selectLocale }}>
      <IntlProvider messages={messages as unknown as Record<string, string>} locale={curLocale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default TranslationWrapper;
