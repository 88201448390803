import { Flex, Text } from "@mantine/core";
import { TariffDetailNode } from "../types/types";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";

//TODO: Combine this and other expanded price display item into single component
export function ExpandedPriceDisplayItemKwh({
  tariffNode,
  currency,
}: {
  tariffNode: TariffDetailNode;
  currency: string;
}) {
  const intl = useIntl();
  return (
    <Flex direction={"row"} justify={"space-between"}>
      <Flex w="100%" mt="md" direction="column" c="#545454">
        <Flex justify="space-between" w="100%" mb="sm">
          <Text>
            {intl.formatMessage(
              { id: i18n.priceUntil },
              {
                value: intl.formatNumber(Number(tariffNode.prices[0].max), {
                  style: "unit",
                }),
              }
            )}{" "}
            {intl.formatMessage({ id: i18n.kW })}
          </Text>
          <Text fw="bold">
            {intl.formatNumber(Number(tariffNode.prices[0].price), {
              style: "currency",
              currency: currency,
            })}
            /{intl.formatMessage({ id: i18n.kwh })}
          </Text>
        </Flex>
        <Flex justify="space-between" w="100%" mb="md">
          <Text>
            {intl.formatMessage(
              { id: i18n.priceAfter },
              {
                value: intl.formatNumber(Number(tariffNode.prices[1].min), {
                  style: "unit",
                }),
              }
            )}{" "}
            {intl.formatMessage({ id: i18n.kW })}
          </Text>
          <Text fw="bold">
            {intl.formatNumber(Number(tariffNode.prices[1].price), {
              style: "currency",
              currency: currency,
            })}
            /{intl.formatMessage({ id: i18n.kwh })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
