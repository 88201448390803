import { Button, Center, Container, Flex, Indicator, Image, Text, useMantineTheme } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import { i18n } from "../i18n/langType";
import { currentChargePoint } from "../atoms/jotaiAtoms";
import { useAtom } from "jotai";
import chargerImage from "../assets/img/placeholder.png";

export function LandingFooter({ setTermsOpened }: { setTermsOpened: React.Dispatch<React.SetStateAction<boolean>> }) {
  const [chargePoint] = useAtom(currentChargePoint);
  const theme = useMantineTheme();
  const handleClick = () => {
    setTermsOpened(true);
  };
  return (
    <footer style={{ backgroundColor: "#EDEFE7", paddingTop: "30px" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "50vh",
          justifyContent: "flex-end", // Pushes the button to the bottom
        }}
      >
        <div
          style={{
            flex: 1, // This makes sure the content area takes the available space
          }}
        >
          {/* Your main content goes here */}
          <Container px="md" py="lg">
            <Flex
              justify="flex-start"
              bg={theme.black}
              px="sm"
              py="32px"
              align="center"
              style={{ borderRadius: "4px" }}
            >
              <Image
                style={{ width: "3rem" }}
                src={chargerImage}
                height="100%"
                fallbackSrc={"chargers/placeholder.png"}
              />
              <Flex gap="xs" direction="column" px="0" mr="auto" ml="sm">
                <Flex gap="xs">
                  <Text color={theme.colors.gray[5]} fw={500} size="sm">
                    EVSE ID
                  </Text>
                  <Text color={theme.white} fw="bold" size="sm">
                    {chargePoint?.chargePointId?.replaceAll("-", "*")}
                  </Text>
                </Flex>
                <Flex>
                  <Text color={theme.colors.gray[6]} size="sm">
                    {chargePoint?.connectorType}
                  </Text>
                </Flex>
                <Flex>
                  <Text color={theme.colors.gray[6]} title={String(chargePoint?.status)} size="sm">
                    <FormattedMessage id={chargePoint?.isAvailable ? i18n.available : i18n.notAvailable} />
                  </Text>
                  <Indicator
                    zIndex={0}
                    color={chargePoint?.isAvailable ? "teal" : "red"}
                    inline
                    size={15}
                    position="middle-center"
                    ml="sm"
                    processing
                  ></Indicator>
                </Flex>
              </Flex>
            </Flex>
          </Container>
        </div>
      </Container>
      {chargePoint?.isAvailable && (
        <Container w="100%" style={{ position: "fixed", bottom: "0px" }} bg={theme.white} px="sm" py="sm">
          <Button
            display="flex"
            onClick={handleClick}
            rightSection={<IconArrowRight />}
            fullWidth
            radius="xl"
            size="lg"
            color="green"
            tt="uppercase"
            style={{
              borderRadius: "80px",
              border: "12px solid white",
              boxSizing: "border-box",
              justifyContent: "center",
              height: "82px",
            }}
          >
            <Text size="md">
              <FormattedMessage id={i18n.confirmChargePointButton} />
            </Text>
          </Button>
        </Container>
      )}
    </footer>
  );
}
