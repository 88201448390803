import { Flex, Text, Title } from "@mantine/core";
import React from "react";
import { i18n } from "../i18n/langType";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { ChargePoint } from "../types/types";
import { ExpandedPriceDisplayItemKwh } from "./expandedPriceDisplayItemKwh";

export default function VariableEnergyDisplay({
  chargePoint,
}: {
  chargePoint: ChargePoint;
}) {
  const intl = useIntl();
  return (
    <div>
      <Title fz="24px" fw="500" mt="lg" mb="lg">
        <FormattedMessage id={i18n.priceInfo} />
      </Title>
      <Title size="h4">{intl.formatMessage({ id: i18n.energyLoad })}</Title>
      <Flex w="100%" mt="md" direction="column" c="#545454">
        <ExpandedPriceDisplayItemKwh
          tariffNode={chargePoint.energyTariff}
          currency={chargePoint.currency}
        />
      </Flex>
    </div>
  );
}
