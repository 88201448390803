import { Button, Card, Container, Flex, Grid, Image, Text, Title, useMantineTheme } from "@mantine/core";
import { IconArrowRight, IconBolt, IconClockHour3, IconWallet } from "@tabler/icons-react";
import { useEffect, useLayoutEffect, useState } from "react";
import HeaderLayout from "../components/layout/Header";
import { countryConfig } from "../utils/countryConfig";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";
import { AuthPaymentFeature } from "../components/paymentAuthorisedFeature";
import { COUNTRY_AVAILABLE, SessionStatusGeneralization, sessionStatusToGeneralization } from "../types/types";
import {
  currentChargePoint,
  currentChargePointId,
  currentUserSessionId,
  currentUserSessionStatus,
  publicKeyStripe,
  userSessionStatusEffect,
} from "../atoms/jotaiAtoms";
import { useAtom, useSetAtom } from "jotai";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { SessionError } from "../components/sessionError";
import { ConnectVehiclePopover } from "../components/connectVehiclePopover";
import BillingInfo from "../components/billingInfo";
import { RESET } from "jotai/utils";

export default function PaymentCompletePage() {
  const [billingInfoOpened, setBillingInfoOpen] = useState(false);
  const params = useParams();
  const intl = useIntl();

  const navigate = useNavigate();
  // Check payment is succeed or failed.
  // If redirect is failed for the payment reason, navigate the error page
  const urlParams = new URLSearchParams(window.location.search);
  const redirectStatus = urlParams.get("redirect_status");
  const { chargerId } = useParams();

  if (redirectStatus === "failed" && chargerId) {
    navigate(`/${chargerId}?error_message_type=payment_faild`);
  }

  const [isBlockingPopoverOpened, setBlockingPopoverOpened] = useState(true);
  const [chargePoint, setChargePoint] = useAtom(currentChargePoint);
  const [chargePointId, setChargePointId] = useAtom(currentChargePointId);
  const [, setUserSessionId] = useAtom(currentUserSessionId);
  const userSessionEffect = useAtom(userSessionStatusEffect);
  const [chargingStatus] = useAtom(currentUserSessionStatus);
  console.log(window.location.href, "window.location.href");

  const isSessionLoading = Boolean(chargingStatus === undefined);

  const isSessionGoing = Boolean(sessionStatusToGeneralization(chargingStatus) == SessionStatusGeneralization.ONGOING);
  const isSessionError = Boolean(sessionStatusToGeneralization(chargingStatus) == SessionStatusGeneralization.ERROR);
  const isSessionCompleted = Boolean(
    sessionStatusToGeneralization(chargingStatus) == SessionStatusGeneralization.COMPLETE
  );

  const setPublicKeyForStripe = useSetAtom(publicKeyStripe);
  useLayoutEffect(() => {
    setPublicKeyForStripe(RESET);
  }, [setPublicKeyForStripe]);

  const theme = useMantineTheme();

  const pageTitle = isSessionCompleted
    ? intl.formatMessage({ id: i18n.sessionComplete })
    : isSessionError
    ? intl.formatMessage({ id: i18n.sessionError })
    : isSessionGoing
    ? intl.formatMessage({ id: i18n.sessionRunning })
    : isSessionLoading
    ? intl.formatMessage({ id: i18n.loadingSession })
    : intl.formatMessage({ id: i18n.sessionHasntStarted });

  const pageDescription = isSessionError ? intl.formatMessage({ id: i18n.refundMessage }) : "";

  useEffect(() => {
    const chargePointIdPath = params.chargerId;
    const userSessionId = params.userSessionId;
    setChargePointId(chargePointIdPath);
    setUserSessionId(userSessionId);
  }, []);

  return (
    <Container bg={"#EEEFE7"} px="0" mih="100vh">
      <HeaderLayout country={chargePoint?.country} title={pageTitle} description={pageDescription} />
      <Flex justify={"center"} align="center" pl="md" pb="md" bg={theme.white}>
        {isSessionError && <SessionError chargePointId={chargePointId} />}
        {isSessionCompleted && (
          <>
            <Title size="h5" mr="sm" style={{ color: "gray.6" }}>
              {intl.formatMessage({ id: i18n.receiptReceived })}
            </Title>
          </>
        )}
      </Flex>
      {!isSessionError && (
        <Grid justify="flex-start" align="strech" bg={theme.white}>
          {
            /* check if chargingStatus.start is not null or undefined */
            chargingStatus?.start != null && (
              <Grid.Col
                span={6}
                style={{
                  borderRight: `1px solid ${theme.colors.gray[0]}`,
                  borderBottom: `1px solid ${theme.colors.gray[0]}`,
                }}
              >
                <AuthPaymentFeature
                  icon={<IconClockHour3 />}
                  name={intl.formatMessage({ id: i18n.start })}
                  value={intl.formatDate(chargingStatus?.start)}
                  subValue={intl.formatTime(chargingStatus?.start)}
                  description=""
                />
              </Grid.Col>
            ) /*TODO:Fix these date formattings*/
          }

          {
            /* check if chargePoint.kwhPrice is not null or undefined */
            chargePoint?.kwhPrice != null && (
              <Grid.Col
                span={6}
                style={{
                  borderBottom: `1px solid ${theme.colors.gray[0]}`,
                }}
              >
                <AuthPaymentFeature
                  icon={<IconWallet />}
                  name={intl.formatMessage({ id: i18n.price })}
                  value={intl.formatNumber(Number(chargePoint?.kwhPrice), {
                    style: "currency",
                    currency: chargePoint.currency,
                  })}
                  description={intl.formatMessage(
                    { id: i18n.currencyPerUnit },
                    { units: "kWh", currency: chargePoint?.currency }
                  )}
                />
              </Grid.Col>
            )
          }
          {
            /* check if chargingStatus.kwh is not null or undefined */
            chargingStatus?.kwh != null && (
              <Grid.Col span={6} style={{ borderRight: `1px solid ${theme.colors.gray[0]}` }}>
                {" "}
                <AuthPaymentFeature
                  icon={<IconBolt />}
                  name={intl.formatMessage({ id: i18n.charged })}
                  value={intl.formatNumber(Number(chargingStatus.kwh))}
                  description={intl.formatMessage({ id: i18n.kwh })}
                />
              </Grid.Col>
            )
          }
          {
            /* check if chargePoint.kwhCapacity is not null or undefined */
            chargePoint?.kwhCapacity !== undefined && (
              <Grid.Col span={6}>
                <AuthPaymentFeature
                  icon={<IconBolt />}
                  name={intl.formatMessage({ id: i18n.capacity })}
                  value={intl.formatNumber(Number(chargePoint?.kwhCapacity))}
                  description={intl.formatMessage({ id: i18n.kW })}
                />
              </Grid.Col>
            )
          }
        </Grid>
      )}
      <Container bg={"black"} p={"1rem"} mt="13px">
        <Flex direction="column">
          <Text c={theme.colors.gray[4]} size="sm" style={{ textAlign: "justify" }}>
            {intl.formatMessage({ id: i18n.billTimingInfo })}
          </Text>
          <Button
            w={"50%"}
            px={"0px"}
            justify="start"
            size="xs"
            style={{ textAlign: "start" }}
            color="none"
            rightSection={<IconArrowRight size="1rem" />}
            onClick={() => {
              setBillingInfoOpen(true);
            }}
          >
            <Text size="sm">{intl.formatMessage({ id: i18n.addEditPersonalInfo })}</Text>
          </Button>
        </Flex>
      </Container>
      <ConnectVehiclePopover
        isOpen={isBlockingPopoverOpened}
        setIsOpen={setBlockingPopoverOpened}
        chargingStatus={chargingStatus}
      />
      <footer
        style={{
          padding: "24px",
          height: "20%",
        }}
      >
        {/* Todo: Make this its own component and change background to meet design on miro, will stay as bottom half of page */}
        <Card bg="white" shadow="md" w="100%" style={{ borderRadius: "1rem" }}>
          <Image src="/apcoa-austria-park-charge.png" width="100%" style={{ maxWidth: "500px" }} />
          {/* why is this image hardcoded to austria park and charge image? */}
          <Title size="md" mt="lg" mb="sm">
            Park + Charge
          </Title>
          <Text>{intl.formatMessage({ id: i18n.parkChargeAd }, { country: chargePoint?.country ?? "DEU" })}</Text>
          <Flex mt="md" direction="column" align="flex-end" justify="flex-end">
            <a target="_blank" href={countryConfig[(chargePoint?.country as COUNTRY_AVAILABLE) || "DEU"]?.productPage}>
              <Button rightSection={<IconArrowRight />} color="dark" radius="xl" size="md" tt="uppercase">
                <Text size="sm">{intl.formatMessage({ id: i18n.learnMore })}</Text>
              </Button>
            </a>
          </Flex>
        </Card>
      </footer>
      <BillingInfo opened={billingInfoOpened} setOpened={setBillingInfoOpen} country={chargePoint?.country} />
    </Container>
  );
}
