import { Box, Container, Drawer, Flex, Grid, LoadingOverlay, rem, useMantineTheme } from "@mantine/core";
import { IconBolt, IconTrafficCone, IconWallet, IconCoins } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import HeaderLayout from "../components/layout/Header";
// import { posthog } from "posthog-js";
import { Feature } from "../components/feature";
import { i18n } from "../i18n/langType";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
// import { TermsAndConditions } from "../components/termsAndConditions";
// import { acceptedTerms, paymentSessionMap } from "~/atoms/nanostores";
//"An error occured outside of our control, sorry for any inconvienence, please make sure you are connected to the internet before continuing. If this problem persists please utlize a different charger, there must be an error with this station."
import { useAtom } from "jotai";
import {
  criticalErrorAtom,
  currentChargePoint,
  currentChargePointId,
  currentUserSession,
  getStatusIntervalAtom,
  loadingAtom,
} from "../atoms/jotaiAtoms";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { TermsAndConditions } from "../components/termsAndConditions";
import VariableEnergyDisplay from "../components/variableEnergyDisplay";
import VariableBlockingFeeDisplay from "../components/variableBlockingFeeDisplay";
import { GeneralErrorModal } from "../components/errorModal";
import { LandingFooter } from "../components/landingFooter";

export interface TermsConditions {
  terms: boolean;
  privacy: boolean;
}

export default function ChargePointLanding() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { chargerId } = useParams();
  const intl = useIntl();
  const [termsOpened, setTermsOpened] = useState(false);
  const [checkedTermsConditions, setChecked] = useState<TermsConditions>({ terms: false, privacy: false });
  const [chargePointId] = useAtom(currentChargePointId);
  const [chargePoint,] = useAtom(currentChargePoint);
  const [statusInterval] = useAtom(getStatusIntervalAtom); // in case user comes back from an errored session, this should be used to stop the interval
  const [criticalError] = useAtom(criticalErrorAtom);
  const [loading] = useAtom(loadingAtom);

  const urlParams = new URLSearchParams(window.location.search);
  const errorMessageType = urlParams.get("error_message_type");
  useEffect(() => {
    if (!chargePointId) {
      console.log("no charge point Id");
    }
    if (statusInterval) {
      clearInterval(statusInterval);
    }
  }, []);

  if (criticalError) {
    navigate("/error");
  }

  return (
    <Container bg="#EDEFE7" px="0" mih="100vh">
      <Box pos="relative">
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "lime", type: "dots" }}
        />
        <HeaderLayout
          country={chargePoint?.country}
          title={
            chargePoint?.isAvailable
              ? intl.formatMessage({ id: i18n.title })
              : intl.formatMessage({ id: i18n.titleFailure })
          }
        />

        <Container fluid bg={theme.white} pb="15px">
          <Grid justify="flex-start" align="stretch" bg={theme.white}>
            <Grid.Col
              span={6}
              style={{
                minHeight: rem(80),
                borderRight: `1px solid ${theme.colors.gray[0]}`,
                borderBottom: `1px solid ${theme.colors.gray[0]}`,
              }}
            >
              {chargePoint?.isVariablePrice ? (
                <Feature
                  icon={<IconWallet color={theme.colors.gray[7]} size={20} />}
                  valueI18n={intl.formatMessage({ id: i18n.variable })}
                  descriptionI18n={intl.formatMessage({ id: i18n.moreInfo })}
                  nameI18n={intl.formatMessage({ id: i18n.price })}
                  hasArrow
                  hasDrawer
                >
                  <VariableEnergyDisplay chargePoint={chargePoint} />
                </Feature>
              ) : (
                Boolean(chargePoint?.kwhPrice) && (
                  <Feature
                    icon={<IconWallet color={theme.colors.gray[7]} size={20} />}
                    valueI18n={intl.formatNumber(Number(chargePoint?.kwhPrice), {
                      style: "currency",
                      currency: chargePoint?.currency,
                    })}
                    descriptionI18n={intl.formatMessage(
                      { id: i18n.currencyPerUnit },
                      { units: "kWh", currency: chargePoint?.currency }
                    )}
                    nameI18n={intl.formatMessage({ id: i18n.price })}
                  />
                )
              )}
            </Grid.Col>
            <Grid.Col span={6} style={{ minHeight: rem(120), borderBottom: `1px solid ${theme.colors.gray[0]}` }}>
              {chargePoint !== undefined && (
                <Feature
                  icon={<IconCoins color={theme.colors.gray[7]} size={20} />}
                  valueI18n={intl.formatNumber(Number(chargePoint?.startTariff.prices[0].price), {
                    style: "currency",
                    currency: chargePoint?.currency,
                  })}
                  descriptionI18n={intl.formatMessage({ id: i18n.oneTime })}
                  nameI18n={intl.formatMessage({ id: i18n.chargingFee })}
                />
              )}
            </Grid.Col>
            <Grid.Col span={6} style={{ borderRight: `1px solid ${theme.colors.gray[0]}` }}>
              {Boolean(chargePoint?.kwhCapacity) && (
                <Feature
                  icon={<IconBolt color={theme.colors.gray[7]} size={20} />}
                  valueI18n={intl.formatNumber(Number(chargePoint?.kwhCapacity))}
                  descriptionI18n={intl.formatMessage({ id: i18n.kW })}
                  nameI18n={intl.formatMessage({ id: i18n.capacity })}
                />
              )}
            </Grid.Col>
            <Grid.Col span={6}>
              {chargePoint?.isVariableBlockingFee ? (
                <Feature
                  noBorder
                  icon={<IconTrafficCone color={theme.colors.gray[7]} size={20} />}
                  nameI18n={intl.formatMessage({ id: i18n.blockingFee })}
                  valueI18n={intl.formatMessage({ id: i18n.variable })}
                  descriptionI18n={intl.formatMessage({ id: i18n.moreInfo })}
                  hasArrow
                  hasDrawer
                >
                  <VariableBlockingFeeDisplay chargePoint={chargePoint} />
                </Feature>
              ) : (
                chargePoint !== undefined && (
                  <Feature
                    noBorder
                    icon={<IconTrafficCone color={theme.colors.gray[7]} size={20} />}
                    valueI18n={intl.formatNumber(Number(chargePoint?.parkingTariff.prices[0].price), {
                      style: "currency",
                      currency: chargePoint?.currency,
                    })}
                    descriptionI18n={intl.formatMessage(
                      { id: i18n.currencyPerUnit },
                      { units: "min", currency: chargePoint?.currency }
                    )}
                    nameI18n={intl.formatMessage({ id: i18n.blockingFee })}
                  />
                )
              )}
            </Grid.Col>
          </Grid>
        </Container>
        <LandingFooter setTermsOpened={setTermsOpened} />
        <GeneralErrorModal
          redirectPath={`/${chargerId}`}
          opened={criticalError || errorMessageType === "payment_faild"}
          message={errorMessageType === "payment_faild" ? intl.formatMessage({ id: i18n.paymentErrorMessage }) : ""}
        />
        {termsOpened && (
          <Drawer
            trapFocus
            closeOnEscape={false}
            closeOnClickOutside={false}
            transitionProps={{
              transition: "slide-up",
              duration: 500,
              timingFunction: "ease",
            }}
            position="bottom"
            opened={termsOpened}
            onClose={() => setTermsOpened(false)}
            padding="sm"
            size="lg"
            withCloseButton={false}
            radius={"lg"}
          >
            <TermsAndConditions
              chargePoint={chargePoint}
              checked={checkedTermsConditions}
              onCheck={setChecked}
              onOpenTerms={setTermsOpened}
              country={chargePoint?.country ?? "DEU"}
            />
          </Drawer>
        )}
      </Box>
    </Container>
  );
}
