import { ActionIcon, Button, Container, Drawer, Flex, Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import { i18n } from "../i18n/langType";
import { ReactNode, useState } from "react";

export const AuthPaymentFeature = ({
  icon,
  name,
  value,
  subValue,
  description,
  hasDrawer,
  children,
}: {
  icon: JSX.Element;
  name: string;
  value: string;
  subValue?: string;
  description: string;
  hasDrawer?: boolean;
  children?: ReactNode;
}) => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  return (
    <Flex
      style={hasDrawer ? { cursor: "pointer", textWrap: "nowrap" } : { textWrap: "nowrap" }}
      gap="none"
      direction="column" /*style={{ borderRight: `1px solid #e1e1e1` }} pr="md"*/
      align={"center"}
    >
      <ThemeIcon
        variant="transparent"
        onClick={() => hasDrawer && setOpened(true)}
        size="lg"
        color={"none"}
        style={{ justifyContent: "flex-start" }}
      >
        {icon}
      </ThemeIcon>
      <Text onClick={() => hasDrawer && setOpened(true)} color={theme.colors.gray[4]} size="sm" fw="light">
        {name}
      </Text>
      <Text
        onClick={() => hasDrawer && setOpened(true)}
        style={{ fontFamily: '"Suisse Intl", sans-serif' }}
        size="md"
        fw="bold"
      >
        {value}
      </Text>
      <Text style={{ fontFamily: '"Suisse Intl", sans-serif' }} size="md" fw="bold">
        {subValue}
      </Text>
      <Text onClick={() => hasDrawer && setOpened(true)} color={theme.colors.gray[4]} size="sm" fw="light">
        {description}
      </Text>
      {hasDrawer && (
        <Drawer
          transitionProps={{
            transition: "slide-up",
            duration: 500,
            timingFunction: "ease",
          }}
          position="bottom"
          opened={opened}
          onClose={() => setOpened(false)}
          padding="sm"
          size="xl"
          withCloseButton={false}
          pos="relative"
        >
          <Container size="lg" pos="relative">
            <Container
              bg={theme.colors.gray[0]}
              h="3px"
              w="xl"
              pos="absolute"
              mx="auto"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%, -50%)" }}
            />
            {children}
            <Flex mt="md" direction="column" align="flex-end" justify="flex-end">
              <Button
                onClick={() => setOpened(false)}
                rightSection={<IconArrowRight />}
                color="dark"
                style={{ fontWeight: 500 }}
                radius="xl"
                size="lg"
                tt="uppercase"
                mb="sm"
              >
                <FormattedMessage id={i18n.close} />
              </Button>
            </Flex>
          </Container>
        </Drawer>
      )}
    </Flex>
  );
};
