export const i18n = {
  title: "title",
  titleFailure: "titleFailure",
  errorChargePointIdMissing: "errorChargePointIdMissing",
  connector: "connector",
  required: "required",
  price: "price",
  currencyPerUnit: "currencyPerUnit",
  capacity: "capacity",
  kwPerHour: "kwPerHour",
  minuteAbbr: "minuteAbbr",
  blockingFee: "blockingFee",
  moreInfo: "moreInfo",
  charged: "charged",
  kwh: "kwh",
  kW: "kW",
  fixedStartPrice: "fixedStartPrice",
  once: "once",
  costAfterRecharge: "costAfterRecharge",
  yes: "yes",
  no: "no",
  confirmChargePointButton: "confirmChargePointButton",
  free: "free",
  startingFromMinute: "startingFromMinute",
  maxAmount: "maxAmount",
  termsConditionsTitle: "termsConditionsTitle",
  termsConditionLabel: "termsConditionLabel",
  privacyDataLabel: "privacyDataLabel",
  termsConditionLink: "termsConditionLink",
  privacyDataLink: "privacyDataLink",
  continue: "continue",
  available: "available",
  notAvailable: "notAvailable",
  chargingPoint: "chargingPoint",
  variable: "variable",
  priceInfo: "priceInfo",
  chargingFee: "chargingFee",
  oneTime: "oneTime",
  energyLoad: "energyLoad",
  priceUntil: "priceUntil",
  priceAfter: "priceAfter",
  oneTimeFeePerCharging: "oneTimeFeePerCharging",
  close: "close",
  emptyObject: "emptyObject",
  helpAndSupport: "helpAndSupport",
  impressum: "impressum",
  emailForSupport: "emailForSupport",
  help: "help",
  receiptReceived: "receiptReceived",
  sessionComplete: "sessionComplete",
  sessionError: "sessionError",
  sessionRunning: "sessionRunning",
  sessionHasntStarted: "sessionHasntStarted",
  refundMessage: "refundMessage",
  start: "start",
  receiptSentAfterSessionEnd: "receiptSentAfterSessionEnd", //This is a terrible name
  pleaseConnectVehicle: "pleaseConnectVehicle",
  chargingAutoStartInfo: "chargingAutoStartInfo",
  ok: "ok",
  learnMore: "learnMore",
  parkChargeAd: "parkChargeAd",
  billTimingInfo: "billTimingInfo",
  tryAgain: "tryAgain",
  emailAddress: "emailAddress",
  authorizationInfo: "authorizationInfo",
  enterAddress: "enterAddress",
  lastName: "lastName",
  firstName: "firstName",
  address: "address",
  postalCode: "postalCode",
  city: "city",
  choosePaymentMethod: "choosePaymentMethod",
  addPaymentDetails: "addPaymentDetails",
  enterEmail: "enterEmail",
  enterPostalCode: "enterPostalCode",
  emailDescription: "emailDescription",
  postCodeDescription: "postcodeDescription",
  email: "email",
  creditCard: "creditCard",
  errorNoInternet: "errorNoInternet",
  paymentInfo: "paymentInfo",
  postCodeInfo: "postCodeInfo",
  authorizePayment: "authorizePayment",
  loadingSession: "loadingSession",
  loadingChargePoint: "loadingChargePoint",
  submitBillingInfo: "submitBillingInfo",
  addEditPersonalInfo: "addEditPersonalInfo",
  errorOccured: "errorOccured",
  genericErrorMessage: "genericErrorMessage",
  redirect: "redirect",
  chooseLanguage: "chooseLanguage",
  billingInfoTitle: "billingInfoTitle",
  backToHome: "backToHome",
  paymentErrorMessage: "paymentErrorMessage",
}; // TODO: maybe organize / split these to be more searchable

export type Translations = typeof i18n;
