import React, { useContext, useState } from "react";
import { Context, getLanguageNameFromLocale } from "./translationWrapper";
import { Flex, Modal, Paper, Text, Checkbox, useMantineTheme, CheckboxGroup } from "@mantine/core";
import { locale } from "../atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";

function LocaleSelector(props: any) {
  const context = useContext(Context);
  const [modalOpen, setModalOpen] = useState(false);
  const [curLocale] = useAtom(locale);
  const intl = useIntl();
  const theme = useMantineTheme();
  return (
    <div className="App">
      <Text
        td="underline"
        unselectable="on"
        style={{ cursor: "default", userSelect: "none", WebkitUserSelect: "none" }}
        onClick={() => setModalOpen(true)}
      >
        {getLanguageNameFromLocale(curLocale)}
        {context.selectLanguage(curLocale)}
      </Text>
      <Modal
        title={intl.formatMessage({ id: i18n.chooseLanguage })}
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Paper>
          <Flex direction={"column"} gap={"md"} justify="center">
            <Checkbox
              display={"flex"}
              pb={"md"}
              mx="md"
              size={"lg"}
              color={"dark"}
              radius={theme.radius.xl}
              checked={curLocale === "en"}
              onClick={() => {
                context.selectLanguage("en");
                setModalOpen(false);
              }}
              labelPosition="left"
              label={"English"}
              value={"en"}
              style={{ borderBottom: "1px grey solid" }}
            />
            <Checkbox
              display={"flex"}
              pb={"md"}
              mx="md"
              size={"lg"}
              color={"dark"}
              radius={theme.radius.xl}
              checked={curLocale === "de"}
              onClick={() => {
                context.selectLanguage("de");
                setModalOpen(false);
              }}
              labelPosition="left"
              label={"Deutsch"}
              value={"de"}
              style={{ borderBottom: "1px grey solid" }}
            />
            <Checkbox
              size={"lg"}
              pb={"md"}
              mx="md"
              color={"dark"}
              radius={theme.radius.xl}
              checked={curLocale === "sv"}
              onClick={() => {
                context.selectLanguage("sv");
                setModalOpen(false);
              }}
              labelPosition="left"
              label={"Svenska"}
              value={"sv"}
            />
          </Flex>
        </Paper>
      </Modal>
    </div>
  );
}
export default LocaleSelector;
