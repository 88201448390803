import { Flex, Text, Title } from "@mantine/core";
import React from "react";
import { i18n } from "../i18n/langType";
import { FormattedMessage, useIntl } from "react-intl";
import { ChargePoint } from "../types/types";
import { ExpandedPriceDisplayItemMinute } from "./expandedPriceDisplayItemMinute";

export default function VariableBlockingFeeDisplay({
  chargePoint,
}: {
  chargePoint: ChargePoint;
}) {
  const intl = useIntl();
  return (
    <div>
      <Title fz="24px" fw="500" mt="xl" mb="xl">
        <FormattedMessage id={i18n.blockingFee} />
      </Title>
      <Title size="h4">
        <FormattedMessage id={i18n.costAfterRecharge} />
      </Title>
      <Flex w="100%" mt="lg" direction="column" c="#545454">
        <ExpandedPriceDisplayItemMinute
          tariffNode={chargePoint.parkingTariff}
          currency={chargePoint.currency}
        />
      </Flex>
    </div>
  );
}
