import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { i18n } from "../i18n/langType";
import { FormEvent, useState } from "react";
import { useIntl } from "react-intl";
import { currentUserSession, loadingStripeAtom } from "../atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { Box, Button, LoadingOverlay } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useAtom(loadingStripeAtom);
  const [userSession] = useAtom(currentUserSession);
  const newUrl = window.location.href.replace(`/pay`, `/complete/${userSession?.user_session_id}`);
  const intl = useIntl();
  let errorString: string | undefined = undefined;
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event: FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: newUrl,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message, "ErrorPayment");
      //determine type of error
      if (result.error.type == "api_error" || "idempotency_error" || "api_connection_error" || "authentication_error")
        // display error component
        errorString = result.error.message;
    } else {
      //I dont think this block of code will be reached
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <div
      style={{
        maxWidth: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{ width: "95%", padding: "0.75rem", backgroundColor: "white", borderRadius: "10px" }}
      >
        <Box pos="relative">
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "lime", type: "dots" }}
          />
          <PaymentElement id="payment-element" onReady={() => setLoading(false)} />
        </Box>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
        <Button
          id="submit"
          disabled={!stripe || !elements || loading}
          display="flex"
          rightSection={<IconArrowRight />}
          fullWidth
          radius="xl"
          size="md"
          color="green"
          tt="uppercase"
          type="submit"
          style={{
            borderRadius: "80px",
            border: "12px solid white",
            boxSizing: "border-box",
            justifyContent: "end",
            height: "82px",
          }}
        >
          <span id="button-text">{intl.formatMessage({ id: i18n.authorizePayment })}</span>
        </Button>
      </form>
    </div>
  );
};

export default CheckoutForm;
