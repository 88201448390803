import { atom } from "jotai";
import { ChargePoint, UserSession, UserSessionStatus } from "../types/types";
import { createUserSession, getChargePointDetails, getUserSessionStatus } from "../api/userSessions";
import { atomEffect } from "jotai-effect";
import { getConfigStripePublishableKeyByCountry } from "../api/stripe";
import { StripeElementLocale } from "@stripe/stripe-js";
import { redirect } from "react-router-dom";
import { atomWithReset } from "jotai/utils";

export const currentChargePointId = atom<string | undefined>(undefined);
export const currentChargePoint = atom<ChargePoint | undefined>(undefined);
export const currentUserSessionId = atom<string | undefined>(undefined);
export const currentUserSession = atom<UserSession | undefined>(undefined);
export const currentChargingSessionId = atom<string | undefined>(undefined);
export const publicKeyStripe = atomWithReset<string | undefined>(undefined);
export const loadingAtom = atom(false);
export const loadingStripeAtom = atom(true);

//will subscribe to ID changes, and create user sessions for each charger
export const userSessionEffect = atomEffect((get, set) => {
  let curId = get(currentChargePointId);
  set(loadingAtom, true);
  if (curId != undefined) {
    createUserSession(curId)
      .then((response) => {
        //todo:Error handling for failed user session call
        set(currentChargePoint, response.chargePoint);
        set(currentUserSession, response.userSessionData);
        getConfigStripePublishableKeyByCountry(response.chargePoint.country).then((response) => {
          set(publicKeyStripe, response?.data.publishableKey);
        });
        set(loadingAtom, false);
      })
      .catch((error) => {
        console.error("Error occured userSessionEffect", error);
        set(criticalErrorAtom, true);
        set(loadingAtom, false);
        return redirect("/error");
      });
  }
});

export const currentUserSessionStatus = atom<UserSessionStatus | undefined>(undefined);
//make an effect that calls the get user session status every minute to update the state
//use the effect on the payment complete page
export const userSessionStatusEffect = atomEffect((get, set) => {
  let curUserSessionId = get(currentUserSessionId);
  let chargePointId = get(currentChargePointId);
  let completeStatusArray = ["TIMEOUT", "ERROR", "COMPLETED", "PAID", "CDR_AVAILABLE"];
  const delay = 6000; //milliseconds of delay in the timeout
  if (curUserSessionId && chargePointId) {
    getChargePointDetails(curUserSessionId, chargePointId)
      .then((response) => {
        if (response !== null) {
          set(currentUserSession, response.userSession);
          set(currentChargePoint, response.chargePoint);
        }
      })
      .catch((error) => {
        console.error("error getting charge point details", error);
        //TODO, retry or show error modal? charge point details are necessary, but reloading the page could be an endless loop for the user,
        //maybe a retry wrapper on chargePointDetails with 10 retries or something along those lines?
      });
    getUserSessionStatus(curUserSessionId)
      .then((response) => set(currentUserSessionStatus, response))
      .catch((error) => console.error("error getting userSessionStatus", error));
  }

  const interval = setInterval(() => {
    let curUserSessionStatus = get(currentUserSessionStatus)?.status;
    let sessionComplete = completeStatusArray.includes(curUserSessionStatus ?? "UNDEFINED");
    if (curUserSessionId !== undefined && !sessionComplete) {
      getUserSessionStatus(curUserSessionId)
        .then((response) => set(currentUserSessionStatus, response))
        .catch((error) => {
          console.error("Error getting userSessionStatus", error);
          //TODO A few missed session statuses are fine, but if we never get updates, that would be bad user experience
        });
    }
  }, delay);
  set(getStatusIntervalAtom, interval);
});

export const getStatusIntervalAtom = atom<NodeJS.Timer | undefined>(undefined);
export const criticalErrorAtom = atom<boolean>(false);
const lang = navigator.language;
let currLocale = atom<StripeElementLocale>("en");
if (lang.startsWith("en")) {
  currLocale = atom<StripeElementLocale>("en");
} else if (lang.startsWith("de")) {
  currLocale = atom<StripeElementLocale>("de");
} else if (lang.startsWith("sv")) {
  currLocale = atom<StripeElementLocale>("sv");
} else {
  currLocale = atom<StripeElementLocale>("en");
}
export const locale = currLocale;
