import { Box, Container, LoadingOverlay, Text, useMantineTheme } from "@mantine/core";
import { useIntl } from "react-intl";
import { i18n } from "../i18n/langType";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
  currentChargePoint,
  currentUserSession,
  loadingStripeAtom,
  locale,
  publicKeyStripe,
} from "../atoms/jotaiAtoms";
import { useAtom } from "jotai";
import StripeCheckoutForm from "../components/stripeCheckoutForm";
import React, { useEffect } from "react";
import HeaderLayout from "../components/layout/Header";
import { CostEstimates } from "../components/costEstimates";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export default function PaymentPage() {
  const theme = useMantineTheme();
  const { chargerId } = useParams();
  const [chargePoint] = useAtom(currentChargePoint);
  const [userSession] = useAtom(currentUserSession);
  const [stripePK] = useAtom(publicKeyStripe);
  const [currentLocale] = useAtom(locale);
  const [loading, setLoading] = useAtom(loadingStripeAtom);
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if(event.persisted) {
        navigate(0);
      }
    }
  
    window.addEventListener('pageshow', handlePageShow);

    return () => window.removeEventListener('pageshow', handlePageShow);
  }, [navigate]);

  const stripePromise = stripePK && loadStripe(stripePK);

  const options: StripeElementsOptions = {
    clientSecret: userSession?.client_secret,
    locale: currentLocale,
  };

  return (
    <div className="container mx-auto bg-stone-100">
      {stripePK === undefined && <Navigate to={`/${chargerId}`} replace={true} />}
      <Box pos="relative">
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "lime", type: "dots" }}
        />
        <HeaderLayout country={chargePoint?.country} title={intl.formatMessage({ id: i18n.choosePaymentMethod })} />
        {stripePromise && (
          <Elements stripe={stripePromise} options={options}>
            <Container size="sm" pos="relative" p={"1rem"}>
              <Text style={{ textAlign: "start", fontSize: "0.75rem", fontWeight: "lighter", paddingBottom: "1rem" }}>
                {intl.formatMessage(
                  { id: i18n.paymentInfo },
                  {
                    authorizedTotal: (
                      <span style={{ fontWeight: "bold" }}>
                        {intl.formatNumber(Number(userSession?.pricing_configuration.authorisation_amount), {
                          style: "currency",
                          currency: chargePoint?.currency,
                        })}
                      </span>
                    ),
                  }
                )}
              </Text>
            </Container>
            <StripeCheckoutForm />
          </Elements>
        )}
      </Box>
    </div>
  );
}
