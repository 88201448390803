import { getAPIPath } from "../utils/settings";

export async function getConfigStripePublishableKeyByCountry(
  countryCode: string
) {
  let baseApiPath = getAPIPath("api/spo/payment/config");
  let response = await fetch(baseApiPath + `/${countryCode}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Max-Age": "86400",
      "User-Agent": "*",
    },
  });
  return await response.json();
}
