import { Button, Flex, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { i18n } from "../i18n/langType";

export function GeneralErrorModal({
  opened,
  redirectPath,
  message,
}: {
  opened: boolean;
  redirectPath: string;
  message?: string;
}) {
  //get chargeId from path probably
  //modal should open whenever there is a flow breaking call (probably failed api call or 401 from stripe)
  //and communicate that there was an error to the user, and give them an option to redirect to first page
  const navigate = useNavigate();
  const intl = useIntl();

  const [hasOpened, { open, close }] = useDisclosure(opened);

  return (
    <Modal withCloseButton={false || !!message} opened={hasOpened} onClose={close}>
      <Stack>
        <Text>{intl.formatMessage({ id: i18n.errorOccured })}</Text>
        <Text>{message ? message : intl.formatMessage({ id: i18n.genericErrorMessage })}</Text>
        {!message && (
          <Button
            onClick={() => {
              navigate(redirectPath);
              navigate(0);
            }}
          >
            {intl.formatMessage({ id: i18n.redirect })}
          </Button>
        )}
      </Stack>
    </Modal>
  );
}
