export enum Status {
  Status_UNKNOWN = 0,
  AVAILABLE = "AVAILABLE",
  BLOCKED = "BLOCKED",
  CHARGING = "CHARGING",
  INOPERATIVE = "INOPERATIVE",
  OUTOFORDER = "OUTOFORDER",
  PLANNED = "PLANNED",
  REMOVED = "REMOVED",
  RESERVED = "RESERVED"
}

export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export interface AuthorizePaymentResponse {
  UserSessionId: string;
  SessionStatus: string;
}

export interface EVILCharger {
  space: Space | undefined;
  status: Status;
  connector_type: ConnectorTypeFromEvil;
  capacity: number;
}

export interface ChargerDetails {
  space: Space | undefined;
  status: Status;
  connector_type: ConnectorTypeFromEvil;
  capacity: number;
}

export interface CreateUserSessionRequest {
  external_charger_id: string;
}

export interface EvResponseAuthorizePaymentResponse {
  timestamp: string;
  data: AuthorizePaymentResponse | undefined;
}

export interface EvResponseUserSession {
  timestamp: string;
  data: UserSession;
  error_code: string;
  error_message: string;
  is_success: boolean;
}

export interface EvResponseUserSessionStatus {
  userSessionId: string;
  status: string;
  start: Date;
  kwh: number;
}

export interface Space {
  country: string;
}

export interface PriceConfig {
  max: number | null;
  min: number | null;
  price: number | null;
}
export interface TariffDetailNode {
  price_type: "VariableTariffElement" | "ConstantTariffElement";
  unit: string;
  prices: PriceConfig[]; //this is an array because if it is a variable tariff element,
  //because the price will change based on the total kw/h used, so 0-10 kwh is priced differently from 10-20kwh
}

export interface PricingConfiguration {
  authorisation_amount: number;
  country_code: string;
  currency: string;
}
export interface Tariff {
  currency: string;
  price_per_kwh: string;
  energy_tariff: TariffDetailNode; // tariff for currency/kwh
  parking_time_tariff: TariffDetailNode; // blocking fee
  start_tariff: TariffDetailNode; //start charging fee
}

export interface UserSession {
  user_session_id: string;
  tariff: Tariff;
  charger_details: ChargerDetails;
  pricing_configuration: PricingConfiguration;
  client_secret: string;
}

export type ChargePoint = {
  chargePointId: string;
  statusCode: string;
  status: string;
  isAvailable: boolean;
  kwhPrice: string;
  blockingFeePerMin?: string;
  country: COUNTRY_AVAILABLE;
  userSessionId: string;
  kwhCapacity?: number;
  connectorType?: string;
  connectorModel: string;
  energyTariff: TariffDetailNode;
  parkingTariff: TariffDetailNode;
  startTariff: TariffDetailNode;
  isVariablePrice: boolean;
  isVariableBlockingFee: boolean;
  currency: string;
};

export type COUNTRY_AVAILABLE = "DEU" | "AUT" | "NOR" | "SWE";

export type PaymentMethod = "creditCard" | "applePay" | "googlePay";

export type PAYMENT_PROCESS_STATUS =
  | "INITIAL"
  | "ENTER_EMAIL"
  | "ENTER_ADDRESS"
  | "ENTER_CARD"
  | "LOADING"
  | "ERROR"
  | "SUCCESS";

export type PaymentStoreProps = {
  errorMessage: string;
  isDrawerOpened: boolean;
  paymentMethod: PaymentMethod;
  status: PAYMENT_PROCESS_STATUS;
  email?: string;
  city?: string;
  address1?: string;
  firstName?: string;
  lastName?: string;
  postalCode?: string;
};

//this is coming from the EVIL layer, can check generated TS from protos on dashboard for types if this needs to be updated, or on EVIL Layer where connector type enum is defined
export enum ConnectorTypeFromEvil {
  CHADEMO = 0,
  DOMESTIC_A = 1,
  DOMESTIC_B = 2,
  DOMESTIC_C = 3,
  DOMESTIC_D = 4,
  DOMESTIC_E = 5,
  DOMESTIC_F = 6,
  DOMESTIC_G = 7,
  DOMESTIC_H = 8,
  DOMESTIC_I = 9,
  DOMESTIC_J = 10,
  DOMESTIC_K = 11,
  DOMESTIC_L = 12,
  IEC_60309_2_single_16 = 13,
  IEC_60309_2_three_16 = 14,
  IEC_60309_2_three_32 = 15,
  IEC_60309_2_three_64 = 16,
  IEC_62196_T1 = 17,
  IEC_62196_T1_COMBO = 18,
  IEC_62196_T2 = 19,
  IEC_62196_T2_COMBO = 20,
  IEC_62196_T3A = 21,
  IEC_62196_T3C = 22,
  TESLA_R = 23,
  TESLA_S = 24,
  PANTOGRAPH_BOTTOM_UP = 25,
  PANTOGRAPH_TOP_DOWN = 26,
  ConnectorType_UNKNOWN = 27,
  UNRECOGNIZED = -1,
}

interface ConnectorTypeForDisplay {
  connectorType: string;
  typeLabel: string;
}
export interface UserSessionStatus {
  userSessionId: string;
  status: string;
  start: Date;
  kwh: number;
}

export enum SessionStatusGeneralization {
  PENDING,
  ONGOING,
  COMPLETE,
  ERROR,
}

//this is coming from the EVIL layer, can check generated TS from protos on dashboard for types if this needs to be updated, or on EVIL Layer where connector type enum is defined
export function connectorTypeFromJSON(object: ConnectorTypeFromEvil | string): ConnectorTypeForDisplay {
  switch (object) {
    case 0:
    case "CHADEMO":
      return {
        connectorType: "Connector type is CHAdeMO, DC",
        typeLabel: "CHAdeMO",
      };
    case 1:
    case "DOMESTIC_A":
      return {
        connectorType: 'Standard/ Domestic household, type "A", NEMA 1 - 15, 2 pins',
        typeLabel: "A",
      };
    case 2:
    case "DOMESTIC_B":
      return {
        connectorType: 'Standard / Domestic household, type "B", NEMA 5 - 15, 3 pins',
        typeLabel: "B",
      };
    case 3:
    case "DOMESTIC_C":
      return {
        connectorType: 'Standard / Domestic household, type "C", CEE 7 / 17, 2 pins',
        typeLabel: "C",
      };
    case 4:
    case "DOMESTIC_D":
      return {
        connectorType: 'Standard / Domestic household, type "D", 3 pin',
        typeLabel: "D",
      };
    case 5:
    case "DOMESTIC_E":
      return {
        connectorType: 'Standard / Domestic household, type "E", CEE 7 / 5 3 pins',
        typeLabel: "E",
      };
    case 6:
    case "DOMESTIC_F":
      return {
        connectorType: 'Standard / Domestic household, type "F", CEE 7 / 4, Schuko, 3 pins',
        typeLabel: "F",
      };
    case 7:
    case "DOMESTIC_G":
      return {
        connectorType: 'Standard / Domestic household, type "G", BS 1363, Commonwealth, 3 pins',
        typeLabel: "G",
      };
    case 8:
    case "DOMESTIC_H":
      return {
        connectorType: 'Standard / Domestic household, type "H", SI - 32, 3 pins',
        typeLabel: "H",
      };
    case 9:
    case "DOMESTIC_I":
      return {
        connectorType: 'Standard / Domestic household, type "I", AS 3112, 3 pins',
        typeLabel: "I",
      };
    case 10:
    case "DOMESTIC_J":
      return {
        connectorType: 'Standard / Domestic household, type "J", SEV 1011, 3 pins',
        typeLabel: "J",
      };
    case 11:
    case "DOMESTIC_K":
      return {
        connectorType: 'Standard / Domestic household, type "K", DS 60884 - 2 - D1, 3 pins',
        typeLabel: "K",
      };
    case 12:
    case "DOMESTIC_L":
      return {
        connectorType: 'Standard / Domestic household, type "L", CEI 23 - 16 - VII, 3 pins',
        typeLabel: "L",
      };
    case 13:
    case "IEC_60309_2_single_16":
      return {
        connectorType: "IEC 60309 - 2 Industrial Connector 1 phase 16A(usually blue)",
        typeLabel: "2 Industrial",
      };
    case 14:
    case "IEC_60309_2_three_16":
      return {
        connectorType: "IEC 60309 - 2 Industrial Connector 3 phase 16A(usually red)",
        typeLabel: "2 Industrial",
      };
    case 15:
    case "IEC_60309_2_three_32":
      return {
        connectorType: "IEC 60309 - 2 Industrial Connector 3 phase 32A(usually red)",
        typeLabel: "2 Industrial",
      };
    case 16:
    case "IEC_60309_2_three_64":
      return {
        connectorType: "IEC 60309 - 2 Industrial Connector 3 phase 64A(usually red)",
        typeLabel: "2 Industrial",
      };
    case 17:
    case "IEC_62196_T1":
      return {
        connectorType: 'IEC 62196 Type 1 "SAE J1772"',
        typeLabel: "1",
      };
    case 18:
    case "IEC_62196_T1_COMBO":
      return { connectorType: "Combo Type 1, DC", typeLabel: "1" };
    case 19:
    case "IEC_62196_T2":
      return {
        connectorType: 'IEC 62196 Type 2 "Mennekes"',
        typeLabel: "2",
      };
    case 20:
    case "IEC_62196_T2_COMBO":
      return { connectorType: "Combo Type 2, DC", typeLabel: "2" };
    case 21:
    case "IEC_62196_T3A":
      return { connectorType: "IEC 62196 Type 3A", typeLabel: "3A" };
    case 22:
    case "IEC_62196_T3C":
      return {
        connectorType: 'IEC 62196 Type 3C "Scame"',
        typeLabel: "3C",
      };
    case 23:
    case "TESLA_R":
      return {
        connectorType: 'Tesla Connector "Roadster" - type(round, 4 pin)',
        typeLabel: "Round, 4 pin",
      };
    case 24:
    case "TESLA_S":
      return {
        connectorType: 'Tesla Connector "Model-S" - type(oval, 5 pin)',
        typeLabel: "Round, 5 pin",
      };
    case -1:
    case "UNRECOGNIZED":
    default:
      return {
        connectorType: "Connector type is unknown",
        typeLabel: "Unknown",
      };
  }
}

export function sessionStatusToGeneralization(chargingStatus: UserSessionStatus | undefined) {
  // console.log("session status", chargingStatus);
  switch (chargingStatus?.status) {
    case "PENDING":
    case undefined:
      return SessionStatusGeneralization.PENDING;
    case "CHARGING":
      return SessionStatusGeneralization.ONGOING;
    case "COMPLETED":
    case "PAID":
    case "CDR_AVAILABLE":
    case "RECEIPT_READY":
    case "RECEIPT_SENT":
    case "FINISHED":
      return SessionStatusGeneralization.COMPLETE;
    case "TIMEOUT":
    case "ERROR":
    case "CANCELLED":
    case "PAYMENT_CANCELLED":
      return SessionStatusGeneralization.ERROR;
    default:
      return SessionStatusGeneralization.ERROR;
  }
}

export interface GetChargePointDetailsResponseData {
  user_session_id: string;
  tariff: Tariff;
  charger_details: ChargerDetails;
}

export interface UserInfo {
  emailAddress: string;
  firstName?: string;
  lastName?: string;
  postalCode?: string;
  address?: string;
  city?: string;
}

export interface EstimateLineProps {
  tariffNode: TariffDetailNode;
  currency: string;
}
