import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TranslationWrapper from "./components/translationWrapper";
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <MantineProvider
      withGlobalClasses
      withCssVariables
      theme={{
        black: "#141414",
        white: "#FAFAFA",
        fontFamily: "Suisse-Intl",
        spacing: {
          // xs: "6", TODO: fix these to match designs if the default ones dont
          // sm: "12",
          // md: "24",
          // lg: "36",
          // xl: "48",
        },
        radius: {
          sm: "16",
          xl: "60",
        },
        colors: {
          gray: [
            "#EEEEEE",
            "#F1F3F5",
            "#E9ECEF",
            "#DEE2E6",
            "#757575",
            "#CBCBCB",
            "#AFAFAF",
            "#545454",
            "#343A40",
            "#212529",
          ],
          green: [
            "#c9e1a3",
            "#bcda8d",
            "#aed376",
            "#a1cb5f",
            "#93c448",
            "#86bc31",
            "#78B51A",
            // "#37B24D",
            "#679C16",
            "#2F9E44",
            "#2B8A3E",
          ],
        },
        headings: {
          fontFamily: '"Suisse-Intl", sans-serif',
          sizes: {
            h1: { fontSize: "30", fontWeight: "600" },
          },
        },
      }}
    >
      <TranslationWrapper>
        <App />
      </TranslationWrapper>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
