import { Button, Checkbox, Container, Flex, Title, Text, useMantineTheme, Paper } from "@mantine/core";
import { SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { i18n } from "../i18n/langType";
import { countryConfig } from "../utils/countryConfig";
import { IconArrowRight } from "@tabler/icons-react";
import { ChargePoint } from "../types/types";
import { useNavigate } from "react-router";

export const TermsAndConditions = ({
  chargePoint,
  checked,
  onCheck,
  onOpenTerms: setTermsOpened,
  country,
}: {
  chargePoint: ChargePoint | undefined;
  checked: {
    terms: boolean;
    privacy: boolean;
  };
  onCheck: React.Dispatch<
    SetStateAction<{
      terms: boolean;
      privacy: boolean;
    }>
  >;
  onOpenTerms: React.Dispatch<React.SetStateAction<boolean>>;
  country: string;
}) => {
  const theme = useMantineTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <Container size="lg" pos="relative">
      <Container
        bg={theme.colors.gray[0]}
        h="3px"
        w="xl"
        mx="auto"
        left="50%"
        style={{ transform: "translate(-50%, -50%)" }}
      />
      <Title size="h3" mt="sm" mb="sm">
        <FormattedMessage id={i18n.termsConditionsTitle} />
      </Title>
      <Flex w="100%" mt="md" gap="xs" direction="column">
        <Flex justify="space-between" w="100%" mb="md">
          <Paper shadow="sm" p={"md"} radius={"md"}>
            <Checkbox
              color={checked ? "dark" : theme.colors.gray[4]}
              radius={theme.radius.xl}
              size={"lg"}
              checked={checked.terms}
              onChange={(event) =>
                onCheck({
                  ...checked,
                  terms: event.currentTarget.checked,
                })
              }
              labelPosition="left"
              label={
                <Text style={{ fontSize: "medium" }}>
                  {intl.formatMessage(
                    { id: i18n.termsConditionLabel },
                    {
                      terms: (
                        <a
                          style={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            backgroundColor: "dark",
                          }}
                          target="_blank"
                          href={countryConfig[country].terms}
                        >
                          {intl.formatMessage({ id: i18n.termsConditionLink })}
                        </a>
                      ),
                    }
                  )}
                </Text>
              }
            />
          </Paper>
        </Flex>
        <Flex justify="space-between" w="100%" mb="md">
          <Paper shadow="sm" p={"md"} radius={"md"}>
            <Checkbox
              radius={theme.radius.xl}
              size={"lg"}
              color={checked ? "dark" : theme.colors.gray[4]}
              checked={checked.privacy}
              onChange={(event) =>
                onCheck({
                  ...checked,
                  privacy: event.currentTarget.checked,
                })
              }
              labelPosition="left"
              label={
                <Text style={{ fontSize: "medium" }}>
                  {intl.formatMessage(
                    { id: i18n.privacyDataLabel },
                    {
                      privacy: (
                        <a
                          style={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            backgroundColor: "dark",
                          }}
                          target="_blank"
                          href={countryConfig[country].privacy}
                        >
                          {intl.formatMessage({ id: i18n.privacyDataLink })}
                        </a>
                      ),
                    }
                  )}
                </Text>
              }
            />
          </Paper>
        </Flex>
      </Flex>
      <Flex mt="md" direction="column" align="flex-end" justify="flex-end">
        {
          <Button
            disabled={!checked.privacy || !checked.terms}
            onClick={() => {
              if (checked.terms && checked.privacy) {
                navigate(`/${chargePoint?.chargePointId}/pay`);
              }
              setTermsOpened(false);
            }}
            rightSection={<IconArrowRight />}
            color="green"
            style={{ fontWeight: 500 }}
            radius={theme.radius.xl}
            size="lg"
            tt="uppercase"
            mb="sm"
          >
            <FormattedMessage id={i18n.continue} />
          </Button>
        }
      </Flex>
    </Container>
  );
};
