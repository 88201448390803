import { Container, Drawer, Image, Title, Text, Flex, Button, useMantineTheme, Divider } from "@mantine/core";
import { i18n } from "../i18n/langType";
import { UserSessionStatus } from "../types/types";
import { useIntl } from "react-intl";

export function ConnectVehiclePopover(props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chargingStatus: UserSessionStatus | undefined;
}) {
  const theme = useMantineTheme();
  const intl = useIntl();

  return (
    <Drawer
      transitionProps={{
        transition: "slide-up",
        duration: 500,
        timingFunction: "ease",
      }}
      pos={"absolute"}
      bottom={"10px"}
      radius={"lg"}
      position="bottom"
      opened={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      padding="sm"
      size="lg"
      withCloseButton={false}
    >
      <Container size="lg" pos="relative">
        <Flex justify={"center"}>
          <Divider size={"sm"} w={"3rem"} />
        </Flex>
        <Image src="/help.png" width="100%" style={{ maxWidth: "500px" }} mt="md" />
        <Title size="md" mt="lg" mb="sm">
          {intl.formatMessage({ id: i18n.pleaseConnectVehicle })}
        </Title>
        <Text>{intl.formatMessage({ id: i18n.chargingAutoStartInfo })}</Text>
        <Flex mt="md" direction="column" align="flex-end" justify="flex-end">
          <Button onClick={() => props.setIsOpen(false)} color="green" radius={"lg"} size="lg" tt="uppercase" mb="sm">
            {intl.formatMessage({ id: i18n.ok })}
          </Button>
        </Flex>
      </Container>
    </Drawer>
  );
}
