import {
  COUNTRY_AVAILABLE,
  ChargePoint,
  CreateUserSessionRequest,
  EvResponseUserSession,
  Status,
  UserInfo,
  UserSession,
  UserSessionStatus,
  connectorTypeFromJSON as connectorTypeFromResponse,
} from "../types/types";
import { getAPIPath } from "../utils/settings";

//TODO: handle errors coming from backend

export async function createUserSession(chargePointId: string): Promise<{
  chargePointId: string;
  userSessionData: UserSession;
  chargePoint: ChargePoint;
}> {
  let userSession;
  userSession = await fetch(getAPIPath("api/spo/payment/create-usersession"), {
    method: "POST",
    body: JSON.stringify({
      external_charger_id: chargePointId,
    } as CreateUserSessionRequest),
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Max-Age": "86400",
      "User-Agent": "*",
    },
  });
  //should we error if we dont get a user session back? I dont understand why we would proceed without one
  const userSessionResponse = (await userSession.json()) as EvResponseUserSession;
  if (!userSessionResponse.is_success) {
    throw userSessionResponse.error_message;
  }

  const userSessionData = userSessionResponse.data;

  const connectorType = connectorTypeFromResponse(userSessionData.charger_details.connector_type);

  const chargePoint: ChargePoint = {
    chargePointId: chargePointId,
    statusCode: String(Status[userSessionData.charger_details?.status ?? "UNRECOGNIZED"]),
    status: String(userSessionData.charger_details?.status),
    isAvailable: Boolean(userSessionData.charger_details?.status == Status.AVAILABLE),
    kwhPrice: userSessionData.tariff.price_per_kwh,
    country: userSessionData.charger_details.space?.country as COUNTRY_AVAILABLE,
    userSessionId: userSessionData.user_session_id,
    kwhCapacity: userSessionData.charger_details.capacity,
    connectorType: connectorType.connectorType,
    connectorModel: connectorType.typeLabel,
    startTariff: userSessionData.tariff.start_tariff,
    energyTariff: userSessionData.tariff.energy_tariff,
    isVariablePrice: userSessionData.tariff?.energy_tariff?.price_type === "VariableTariffElement",
    isVariableBlockingFee: userSessionData.tariff?.parking_time_tariff?.price_type === "VariableTariffElement",
    parkingTariff: userSessionData.tariff.parking_time_tariff,
    currency: userSessionData.pricing_configuration.currency,
  };
  return {
    chargePointId: chargePointId,
    userSessionData: userSessionData,
    chargePoint,
  };
}

export async function getUserSessionStatus(userSessionId: string) {
  const userSessionStatus = await fetch(getAPIPath(`api/spo/payment/usersession/${userSessionId}/status`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Max-Age": "86400",
      "User-Agent": "*",
    },
    cache: "no-cache",
  });
  const sessionStatusResponse = (await userSessionStatus.json()).data as UserSessionStatus;

  // console.log("session status response", sessionStatusResponse);
  return sessionStatusResponse;
}

export async function getChargePointDetails(userSessionId: string, chargePointId: string) {
  const fetchChargePointDetails = await fetch(
    getAPIPath(`/api/spo/payment/usersession/${userSessionId}/get-charge-point-details`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Max-Age": "86400",
        "User-Agent": "*",
      },
      cache: "no-cache",
    }
  );
  if (fetchChargePointDetails.status > 299) {
    console.error("Error fetching charge point details");
    return null;
  }

  const userSessionDetails = (await fetchChargePointDetails.json()).data as UserSession; // Is not quite a user session as  pricing configuration and client secret are null
  console.log("fetch charge point details", userSessionDetails);
  const connectorType = connectorTypeFromResponse(userSessionDetails.charger_details.connector_type);
  const chargePoint: ChargePoint = {
    chargePointId: chargePointId,
    statusCode: String(Status[userSessionDetails.charger_details?.status ?? "UNRECOGNIZED"]),
    status: String(userSessionDetails.charger_details?.status),
    isAvailable: Boolean(userSessionDetails.charger_details?.status == Status.AVAILABLE),
    kwhPrice: userSessionDetails.tariff.price_per_kwh,
    country: userSessionDetails.charger_details.space?.country as COUNTRY_AVAILABLE,
    userSessionId: userSessionDetails.user_session_id,
    kwhCapacity: userSessionDetails.charger_details.capacity,
    connectorType: connectorType.connectorType,
    connectorModel: connectorType.typeLabel,
    startTariff: userSessionDetails.tariff.start_tariff,
    energyTariff: userSessionDetails.tariff.energy_tariff,
    isVariablePrice: userSessionDetails.tariff?.energy_tariff?.price_type === "VariableTariffElement",
    isVariableBlockingFee: userSessionDetails.tariff?.parking_time_tariff?.price_type === "VariableTariffElement",
    parkingTariff: userSessionDetails.tariff.parking_time_tariff,
    currency: userSessionDetails.tariff.currency,
  };
  // console.log("chargePointDetails response", userSessionDetails, chargePoint);
  return { userSession: userSessionDetails, chargePoint: chargePoint };
}

export async function updateBillingInfo(userInfo: UserInfo, userSessionId: string, countryCode: string) {
  return await fetch(getAPIPath("api/spo/payment/usersession/billing-details"), {
    method: "POST",
    body: JSON.stringify({
      email: userInfo.emailAddress,
      user_session_id: userSessionId,
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
      address: userInfo.address,
      city: userInfo.city,
      postal_code: userInfo.postalCode,
      country: countryCode, // for user info? Why does this come from charger?
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }); //TODO: show some sort of success somewhere
}
