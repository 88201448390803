import { Flex, Text, Title } from "@mantine/core";
import { EstimateLineProps, TariffDetailNode } from "../types/types";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { i18n } from "../i18n/langType";

//TODO: Combine this and other expanded price display item into single component
export function ExpandedPriceDisplayItemMinute({
  tariffNode,
  currency,
}: {
  tariffNode: TariffDetailNode;
  currency: string;
}) {
  const intl = useIntl();
  return (
    <div>
      <Flex justify="space-between" w="100%" mb="sm">
        <Text>
          {intl.formatMessage(
            { id: i18n.priceUntil },
            {
              value: intl.formatNumber(Number(tariffNode.prices[0].max), {
                style: "unit",
                unit: "minute",
              }),
            }
          )}
        </Text>
        <Text fw="bold">
          {intl.formatNumber(Number(tariffNode.prices[0].price), {
            style: "currency",
            currency: currency,
          })}
          /{intl.formatMessage({ id: i18n.minuteAbbr })}
        </Text>
      </Flex>
      <Flex justify="space-between" w="100%" mb="md">
        <Text>
          <FormattedMessage
            id={i18n.priceAfter}
            values={{
              value: intl.formatNumber(Number(tariffNode.prices[1].min), {
                style: "unit",
                unit: "minute",
              }),
            }}
          />
        </Text>
        <Text fw="bold">
          <FormattedNumber
            style="currency"
            currency={currency}
            value={Number(tariffNode.prices[1].price)}
          />
          /{intl.formatMessage({ id: i18n.minuteAbbr })}
        </Text>
      </Flex>
    </div>
  );
}
