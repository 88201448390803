import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { currentChargePointId, userSessionEffect } from "../atoms/jotaiAtoms";
import ChargePointLanding from "./chargePointLanding";


export default function ChargePointLandingLoader(){
    const id = useParams().chargerId;
    const [chargerId, setChargerId] = useAtom(currentChargePointId);
    const atomEffect = useAtom(userSessionEffect);

    useEffect(() => {
      setChargerId(id);
    }, [id]); //todo: identify when id is blank to navigate to error page
  
    if (chargerId !== id) {
        return null; //todo: Return loading page
    }
return <ChargePointLanding/>}